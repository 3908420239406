import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VRow,
    { attrs: { justify: "end" } },
    [
      !_vm.gs.isMobile()
        ? _c(
            VCol,
            { staticClass: "pa-0 text-center", attrs: { cols: "12", md: "7" } },
            [
              _c("img", {
                staticStyle: {
                  padding: "48px",
                  "object-fit": "contain",
                  width: "inherit",
                  "max-height": "950px",
                },
                attrs: {
                  alt: "banker Register",
                  src: require("@/assets/banker-register-bg.svg"),
                },
              }),
            ]
          )
        : _vm._e(),
      _c(
        VCol,
        {
          staticClass: "pa-0",
          staticStyle: {
            display: "flex",
            "justify-content": "start",
            "align-items": "center",
          },
          attrs: { cols: "12", md: "5" },
        },
        [
          _c(
            "div",
            { class: "" + (_vm.gs.isMobile() ? "px-4" : "pr-12") },
            [
              _vm.loading
                ? _c(
                    VRow,
                    { staticClass: "my-4", attrs: { justify: "center" } },
                    [
                      _c(VProgressCircular, {
                        attrs: { indeterminate: "", size: "20" },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        VRow,
                        {
                          class:
                            "text-" +
                            (_vm.gs.isMobile() ? "center" : "left") +
                            " " +
                            (_vm.gs.isMobile() && "py-2"),
                          attrs: { justify: "center", align: "center" },
                        },
                        [
                          _c(VCol, { attrs: { cols: "12" } }, [
                            _c(
                              "h1",
                              {
                                class: "px-5 " + (_vm.gs.isMobile() && "mt-8"),
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "var(--white)",
                                  "font-size": "41px",
                                  "line-height": "42px",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("banker_register_1")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(VCol, { attrs: { cols: "12" } }, [
                            _c(
                              "h5",
                              {
                                staticClass: "px-5 my-3 mb-5",
                                staticStyle: {
                                  "font-size": "18px",
                                  "line-height": "21px",
                                  color: "var(--white)",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("banker_register_2")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            VCol,
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass:
                                    "px-5 dxa-btn-primary-blue register-btn",
                                  attrs: {
                                    "data-test": "banker:signup:button",
                                  },
                                  on: { click: _vm.redirect_to_register },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("register_action")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { staticClass: "mt-2", attrs: { cols: "12" } },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "ml-5",
                                  staticStyle: {
                                    "font-size": "16px",
                                    "text-decoration": "underline",
                                    color: "#1a7dff",
                                    "font-weight": "700",
                                  },
                                  on: { click: _vm.redirect_to_login },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("already_registered")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "py-6",
                          class: "" + (_vm.gs.isMobile() ? "px-4" : ""),
                        },
                        [
                          _c(
                            "h2",
                            {
                              staticClass: "text-center",
                              staticStyle: {
                                color: "var(--white)",
                                "font-size": "30px",
                                "font-weight": "bolder",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("alpha_register_1_info_1_main")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "h5",
                            {
                              staticClass: "text-center",
                              staticStyle: {
                                "font-size": "18px",
                                color: "var(--white)",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("alpha_register_1_info_1_desc")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "h2",
                            {
                              staticClass: "text-center mt-4",
                              staticStyle: {
                                color: "var(--white)",
                                "font-size": "30px",
                                "font-weight": "bolder",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("alpha_register_1_info_2_main")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "h5",
                            {
                              staticClass: "text-center",
                              staticStyle: {
                                "font-size": "18px",
                                color: "var(--white)",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("alpha_register_1_info_2_desc")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ]
      ),
      _c(VCol, { staticClass: "pa-0", attrs: { cols: "0", md: "1" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }